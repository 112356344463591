import React from "react"
import "./layout.css"
import Helmet from 'react-helmet'

export default ({ children }) => (
  <div className="wedding-layout">
    <div className="page-content">
      {children}
    </div>
  </div>
);