
import React from "react"
import WeddingLayout from "../components/wedding_layout"
import SEO from '../components/seo';

export default () => (
  <WeddingLayout>
    <SEO title="Boda de Ivette & Jeremy | Kiwi & Cacahuate" description="Ivette & Jeremy's Wedding" url="https://kiwicacahuate.com/wedding/" image="https://kiwicacahuate.com/wedding/wedding.jpg" />


    <div className="wedding">
      <img src="/wedding/title-text-wedding.svg" className="image" alt="Wedding"/>
      <img src="/wedding/full-no-text.jpg" className="image" alt="Wedding" />
      <img src="/wedding/main-text-wedding.svg" className="image" width='425' style={{width: 425}} alt="Wedding" />

      <div style={{maxWidth: 800, margin: '40px auto 60px', display: 'flex'}}>
        <div className="box location">
          <h3><span>Ubicación / Location</span></h3>
          <p>Hacienda Castillo <br />
            Libramiento Sur-Poniente KM.20<br /> Col. Castillo 38180<br /> Querétaro</p>
          <p className="address"><a href="https://www.google.com/maps?q=Libramiento Sur-Poniente KM.20, Col. Castillo,38180,Querétaro" target="_blank">Show in Map</a></p>
        </div>

        <div className="box guide">
          <h3><span>Guide</span></h3>
          <p>We have prepared a <strong>Guide to Queretaro</strong> for people visiting for the first time, with information about how to get there, where to stay and what to do and eat.</p>
          <p className="address"><a target="_blank" href="https://www.notion.so/QUER-TARO-Accommodation-Things-to-do-Where-to-eat-and-8923f00add09433da5735d896781f958">Click for the Guide here!</a></p>
        </div>
      </div>

      <iframe title="form" src="https://docs.google.com/forms/d/e/1FAIpQLSe9AQ-9XbyMQhSYRtbH9O4seFQl2TCr7kIq9V-EkTgmgOq19A/viewform?embedded=true" width="640" height="1700" frameborder="0" marginheight="0" marginwidth="0" style={{display: 'block', margin: '20px auto'}}>Loading...</iframe>



    </div>

  </WeddingLayout>
)

